<template>
  <component :is="DataTable" 
    :loading="isLoading" 
    v-model:filters="filters" 
    :paginator = paginator 
    v-model:selection="selectedRows"
    :rows="20"
    :rowsPerPageOptions="[10, 20, 30, 50, 100]" 
    showGridlines 
    stripedRows 
    scrollable 
    scrollHeight="100px"
    removableSort 
    ref="datatable" 
    :sortField="'Id'"  
    :sortOrder="1"           
    :value="tableItems" 
    paginator-template="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
    current-page-report-template="Exibir {first} a {last} de {totalRecords} itens"
    :globalFilterFields="tableCols?.map((item)=> item.field)">
    <template #empty> Nenhum registro encontrado. </template>
    <template #header v-if="haveHeader || $slots?.header">
      <slot v-if="$slots?.header" name="header" :filterValue="filters" :setFilterValue="(value, col = 'global') => setFilter(value, col)" />
      <div class="flex p-4" v-else>
          <div class="mr-auto flex items-center gap-2"> 
            <div class="flex-items-center relative">
              <component :is="InputText" v-model="filters.global.value" placeholder="Pesquisar" aria-label="Global Search"
                class="" />
              <component :is="IconSearch"
                class="absolute bg-white pointer-events-none top-1/2 right-2 transform -translate-y-1/2 w-5 h-5 text-gray-500" />
            </div>
          </div>

          <div class="flex items-center gap-2">              
            <component :is="Button" class="button" label="Adicionar" @click="createFunction()">
              <component :is="IconAddCircle" />
              Adicionar
            </component>
            <component :is="Button" class="button-icon" @click="refreshFunction()">
              <component :is="IconRefresh" />
            </component>
          </div>
        </div>
    </template>
    <component :is="Column" v-if="selectionMode" :selectionMode="selectionMode" headerStyle="width: 3rem"></component>
    <component :is ="Column"
      v-for="(data, i) in tableCols"
      :field="data.field" 
      :header="data.title" 
      sortable 
      filter 
      :style="{ width: data.width }" 
      :key="i"
     >
     <template #body="slotProps">
      <slot v-if="$slots[data.field]" :name="data.field" :data="slotProps.data" />
      <div class="flex gap-4 items-center" v-else-if="data?.isFile">
        <img
          v-if="handleSelectFileIcon(slotProps.data.name)"
          :src="handleSelectFileIcon(slotProps.data.name).iconClass"
          :style="handleSelectFileIcon(slotProps.data.name).style"
        />
        <div class="">
          <p class="m-0 text-ellipsis overflow-hidden">{{ slotProps.data.name }}</p>
          <p class="m-0">{{ formatBytes(slotProps.data.size) }}</p>
        </div>
      </div>  
      <span v-else-if="data.field.toLowerCase() === 'id'" :style="{ color: '#0053A1' }">
        {{ slotProps.data[data.field] }}
      </span> 
      
      <!-- Campos padrão -->
      <span v-else :class="[fillStatusLabel(slotProps.data[data.field]), data?.customClass]">
        {{ slotProps.data[data.field] }}
      </span>
    </template>
        <template #sorticon="{ sorted, sortOrder }">
          <span class="bh-ml-3 bh-sort bh-flex bh-items-center id asc">
            <svg width="16" height="16" viewBox="0 0 14 14" fill="none">
              <polygon :class="{
                'text-black-second': sorted && sortOrder === -1
              }" points="3.11,6.25 10.89,6.25 7,1.75" fill="currentColor" class="bh-text-black/20"></polygon>
              <polygon :class="{
                'text-black-second': sorted && sortOrder === 1
              }" points="7,12.25 10.89,7.75 3.11,7.75" fill="currentColor" class="bh-text-black/20"></polygon>
            </svg>
          </span>
        </template>
      </component>
      <component :is="Column" 
        v-if="rowActions || $slots?.rowActions" 
        alignFrozen="right" 
        frozen    
        :header="!refreshFunction ? 'Ações':''"
      >
        <template #header v-if="refreshFunction">
          Ações
          <button v-if="!(haveHeader || $slots?.header)" @click="refreshFunction()" class="text-primary-second hover:text-primary ml-6">
            <component :is="iconRefreshTable" />
          </button>
        </template>
        <template #body="slotProps">
          <div v-if="!$slots?.rowActions" class="flex gap-4 items-center mr-1">
            <component :is="Button" v-for="(button, i) of rowActions" :key="i"
              :class="`text-primary-second hover:text-primary  static ${typeof button.customClass === 'function' ? button.customClass(slotProps.data) : button.customClass }`"  v-tooltip.bottom="typeof button.label === 'function' ? button.label(slotProps.data) : button.label"
              @click="button.onPress(slotProps.data)"
              type="button"
             :disabled="typeof button?.disabled === 'function'  ? button.disabled(slotProps.data) : !!button?.disabled"
            >
              <component :is="typeof button.icon === 'function' ? button.icon(slotProps.data) : button.icon" />
            </component>
          </div> 
          <slot name="rowActions" :data="slotProps.data" v-else />
        </template>
      </component>        
     </component>
</template>
<script>
import iconRefreshTable from './icon/icon-refresh-table.vue';
import IconSearch from './icon/icon-search.vue';
import IconAddCircle from './icon/icon-add-circle.vue';
import IconRefresh from './icon/icon-refresh.vue';
import { handleSelectFileIcon } from '../service/evidence-icons';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

export default {
name: 'UiTable',
props: {
  tableItems: {
   type: Array,
   required: true
  },
  tableCols: {
    type: Array,
    required: true
  },
  rowActions: {
    type: Array,
    required: false
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false
  },
  paginator: {
    type: Boolean,
    required: false,
    default: true
  },
  refreshFunction: {
    type: Function,
    required: false
  },
  haveHeader: {
    type: Boolean,
    required: false
  },
  createFunction: {
    type: Function,
    required: false
  },
  selectionMode: {
    type: String,
    required: false,
  },
  selection: {
    type: Array,
    required: false,
    default: () => []
  }  
},
data() {
  return {
    filters: {
      global: {
        value: null
      },
    }
  }
},
emit: ['update:selection'],
computed: {
 selectedRows: {
   get() {
     return this.selection;
   },
   set(value) {
     this.$emit('update:selection', value);
   },
 }
},
methods: {
    fillStatusLabel(status) {
      switch (status) {
        case 'Ativo':
        case 'Desbloqueado':
          return 'badge font-normal rounded-[10px] text-success border-success'
        case 'Inativo':
        case 'Bloqueado': 
          return 'badge font-normal rounded-[10px] text-danger border-danger'       
        default:
          return ''
      }
  },  
  setFilter(value, col) {
    if(col === 'global') {
      this.filters.global.value = value
    } else {
     this.filters[col].constraints = value
    }
  },
  formatBytes(bytes) {
    if (bytes === 0) return '0 Bytes';

    const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const value = bytes / Math.pow(1024, i);

    // Ajuste para ter 2 casas decimais
    return `${value.toFixed(2)} ${sizes[i]}`;
  }
},
created() {
  this.tableCols.forEach((col) => {
    this.filters[col.field] = {
      operator: 'or',
      constraints: [],
    }
  });
},
setup() {
  return {      
    iconRefreshTable,
    handleSelectFileIcon,
    DataTable,
    Column,
    InputText,
    Button,
    IconSearch,
    IconAddCircle,
    IconRefresh
  }
}
}

</script>
