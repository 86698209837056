import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { nhost } from "../main";
import { isUserBlocked } from "@/service/blocking";
import { updateRole } from "@/service/roles";

const routes: RouteRecordRaw[] = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: "page-not-found" },
  },
  {
    path: "/",
    children: [
      {
        path: "/access-denied-m",
        name: "access-denied-m",
        component: () => import("@/components/errors/AccessDeniedM.vue"),
      },
      {
        path: "/access-denied",
        name: "access-denied",
        component: () => import("@/components/errors/AccessDenied.vue"),
      },
      {
        path: "/page-not-found",
        name: "page-not-found",
        component: () => import("@/components/errors/PageNotFound.vue"),
      },
    ],
  },
  {
    path: "/",
    meta: {
      requiresAuth: true,
      requiresPickedTenant: true,
      allowedRoles: [
        "dm-dpo",
        "tenant_admin_dm",
        "dm-manager",
        "dm-respondent",
        "tenant_admin_dm_bay",
      ],
    },
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/Home.vue"),
        meta: { breadcrumb: "HOME" },
      },
      {
        meta: {
          allowedRoles: ["tenant_admin_dm", "tenant_admin_dm_bay"],
        },
        path: "/config",
        children: [
          {
            path: "",
            name: "config",
            component: () => import("@/views/ConfigDash.vue"),
            meta: { breadcrumb: "Configurações" },
          },
          {
            path: ":table",
            name: "config-table",
            component: () => import("@/views/ConfigTable.vue"),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.meta.requiresAuth;
  const requiresPickedTenant = to.meta.requiresPickedTenant;
  const allowedRoles = to.meta.allowedRoles as string[];
  const isAuthenticated = await nhost.auth.isAuthenticatedAsync();
  nhost.auth.refreshSession();
  const hasPickedTenant = !!nhost.auth.getHasuraClaim("x-hasura-Tenant-Now-Id");

  //esta logado
  if (requiresAuth && !isAuthenticated) {
    window.location.href = "/auth#/login";
    return;
  }
  const role = await updateRole();

  if (requiresPickedTenant && !hasPickedTenant) {
    const encodedRedirect = encodeURIComponent(
      `/modules/mapeamento-de-dados/#/${to.fullPath as string}`
    );
    window.location.href = `/auth#/setup/setup-choice${encodedRedirect}`;
    return;
  }
  if ((await isUserBlocked()) && to.fullPath != "/access-denied-m") {
    next({ name: "access-denied-m" });
    return;
  }
  if (
    allowedRoles &&
    !allowedRoles.includes(role) &&
    to.fullPath != "/access-denied"
  ) {
    next({ name: "access-denied" });
    return;
  }
  next();
});
export default router;
