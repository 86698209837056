import "core-js/modules/web.timers.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "gestao-de-riscos"
};
import { onMounted } from "vue";
import { useAppStore } from "@/stores/index";
import appLayout from "@/layout/app-layout.vue";
import { usePrimeVue } from "primevue/config";
import { useI18n } from "vue-i18n";
import packageJSON from "../package.json";
export default {
  __name: 'App',
  setup: function setup(__props) {
    var _useI18n = useI18n(),
      t = _useI18n.t;
    var version = packageJSON.version;
    localStorage.setItem("module_version", version);
    onMounted(function () {
      var primevue = usePrimeVue();
      primevue.config.locale.dayNamesShort = t("locale.dayNamesShort").split(" ");
      primevue.config.locale.dayNamesMin = t("locale.dayNamesMin").split(" ");
      primevue.config.locale.monthNamesShort = t("locale.monthNamesShort").split(" ");
      primevue.config.locale.dayNames = t("locale.dayNames").split(" ");
      primevue.config.locale.monthNames = t("locale.monthNames").split(" ");
      primevue.config.locale.completed = t("locale.completed");
      setTimeout(function () {
        document.getElementById("loaderID").style.display = "none";
      }, 3000);
    });
    var store = useAppStore();
    return function (_ctx, _cache) {
      var _component_Toast = _resolveComponent("Toast");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Toast), _createElementVNode("main", _hoisted_1, [_createElementVNode("div", {
        "class": _normalizeClass(["main-section antialiased relative text-sm font-normal", [_unref(store).sidebar ? 'toggle-sidebar' : '', _unref(store).menu, _unref(store).rtlClass]])
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent(appLayout)))], 2)])], 64);
    };
  }
};