import { tableItems } from "./../service/table-data";
import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { TableItemProps, LinkedTaskProps } from "@/service/table-data";

// this is our 'plano de ação' app store
interface AppStoreProps {
  dataItems: TableItemProps[];
  filteredDataItems: TableItemProps[];
  initialTableCols: Ref<object[]>;
  favoriteItems: TableItemProps[];
  searchInput: Ref<string>;
  sidebarFilterKey: Ref<string>;
  isShowSidebar: Ref<boolean>;
  currentSelectedPlan: Ref<TableItemProps | null>;
  selectedTaskRow: Ref<LinkedTaskProps | null>;
}

export const useAppStore = defineStore("appStore", {
  state: (): AppStoreProps => ({
    dataItems: tableItems,
    filteredDataItems: [],
    initialTableCols: ref([]),
    favoriteItems: [],
    searchInput: ref(""),
    sidebarFilterKey: ref(""),
    isShowSidebar: ref(true),
    currentSelectedPlan: ref(null),
    selectedTaskRow: ref(null),
  }),
  actions: {
    setInitialTableItems(payload: any) {
      this.filteredDataItems = payload;
    },
    setTableFilter(payload: string, key?: "status" | null) {
      if (payload == "all") {
        return (this.filteredDataItems = this.dataItems);
      }
      if (key === "status") {
        return (this.filteredDataItems = this.dataItems.filter(
          (item) => item.status.label === payload
        ));
      }

      const filteredItems = () => {
        if (payload == "delayed") {
          const today = new Date();
          console.log("entrei");

          return this.dataItems.filter((item) => {
            return new Date(item.deadline) < today;
          });
        }
        if (payload == "in progress") {
          const statusValues = ["Plano criado", "Em andamento", "finalizado"];

          return this.dataItems.filter((item) => {
            return statusValues.some((status) =>
              item.status.label.includes(status)
            );
          });
        }
        if (payload == "finished") {
          const statusValues = [
            "Plano encerrado",
            "Plano reprovado",
            "Plano aprovado",
          ];

          return this.dataItems.filter((item) => {
            return statusValues.some((status) =>
              item.status.label.includes(status)
            );
          });
        }
        if (payload == "favorite") {
          return this.favoriteItems;
        }
      };

      this.filteredDataItems = filteredItems();
    },
    setInitialTableCols(payload: any) {
      this.initialTableCols = payload;
    },
    addRowToFavorite(payload: any) {
      this.favoriteItems = [...this.favoriteItems, payload];
    },
    removeRowFromFavorite(payload: any) {
      const index = this.favoriteItems.findIndex(
        (item) => item.COD == payload.COD
      );

      this.favoriteItems = [
        ...this.favoriteItems.slice(0, index),
        ...this.favoriteItems.slice(index + 1),
      ];
    },
    setSearchInput(payload: string) {
      this.searchInput = payload;
    },
    setSidebarFilterKey(payload: string) {
      this.sidebarFilterKey = payload;
    },
    toggleSidebar() {
      this.isShowSidebar = !this.isShowSidebar;
    },
    setCurrentSelectedPlan(payload: TableItemProps | null) {
      if (this.dataItems.length === 0) return;

      this.currentSelectedPlan = payload;

      console.log(this.currentSelectedPlan);
    },
    setSelectedTaskRow(payload: LinkedTaskProps | null) {
      this.selectedTaskRow = payload;
    },
  },
});
