<template>
  <div
    class="custom-card h-full p-4 flex-none min-w-[240px] max-w-[25%] transition-all delay-75 z-10 space-y-4 xl:block xl:rounded-r-md rounded-r-none animate-appears"
  >
    <div class="flex flex-col h-full">
      <div class="flex items-center gap-1 mb-4 text-center text-dark-second">
        <slot name="header"/>
      </div>
      <div class="h-px w-full border-b border-[#e0e6ed] mb-4"></div>
      <div class="flex flex-col gap-4">
        <!-- BUTTONS START  -->
        <div class="flex flex-col gap-1">
          <template v-if="loading">
            <div v-for="iS in 7" :key="`sidebar-skeleton-${iS}`" class="flex justify-between items-center p-2 rounded-md h-10">
              <div class="flex items-center gap-2">
                <component :is="Skeleton" shape="circle" size="1.5rem" />
                <component :is="Skeleton" width="80px" height="16px" />
              </div>
              <component :is="Skeleton" width="30px" height="16px" />
            </div>
          </template>
          <template v-else>
          <button
            v-for="(itemB, iButtonAction) in sidebarButtons?.buttons"
            :key="`sidebar-button-${iButtonAction}`"
            type="button"
            class="w-full flex justify-between items-center p-2 hover:bg-black-light rounded-md group group-hover:text-primary text-sidebar text-dark-second h-10"
            :class="{
              'bg-black-light text-primary': activeSidebarValue === itemB.tab,
            }"
            @click="$emit('buttonClick', itemB.tab)"
          >
            <div class="flex items-center">
              <component :is="itemB.icon" v-if="itemB.icon" class="icon-size-sidebar shrink-0 group-hover:text-primary" />
              <div class="ml-2 group-hover:text-primary">{{ itemB.label }}</div>
            </div>
            <div
              v-if="'count' in itemB"
              class="bg-black-light rounded-md py-0.5 px-2 whitespace-nowrap group-hover:bg-primary-light group-hover:text-primary"
              :class="{ 'bg-primary-light': activeSidebarValue === itemB.tab }">
              {{
                itemB.count
              }}
            </div>
          </button>
          </template>
        </div>
        <div class="h-px w-full border-b border-[#e0e6ed]"></div>
        <!-- STATUS START  -->
        <div class="status-sidebar">
          <slot name="statusTitle">
            <p class="text-white-dark mb-2">{{ statusTitle }}</p>
          </slot>          
          <div class="flex flex-col gap-1">
          <template v-if="loading">
            <div v-for="iStatus in 3" :key="`sidebar-skeleton-status-${iStatus}`" class="flex items-center gap-4">
              <component :is="Skeleton" class="!h-5 !w-3/4"/>
            </div>
          </template>
          <template v-else>
            <button
              v-for="(item, iStatusAction) in sidebarButtons?.status"
              :key="`sidebar-status-action-${iStatusAction}`"
              type="button"
              class="w-full flex items-center h-8 hover:bg-black-light rounded-md text-sidebar hover:pl-2 duration-300"
              :class="`${activeSidebarValue === item.label ? 'bg-black-light pl-2' : ''
                }` + ` text-${item.color}`
              "
              @click="$emit('statusClick', item.label)"
            >
              <component :is="IconSquareRotated" :class="`${item.fill}` + ' shrink-0'" />
              <div class="ml-2">{{ item.label }}</div>
            </button>
          </template>
          </div>
        </div>
      </div>
      <slot name="footer">
        <Button
          class="btn-trustech btn-primary-second text-sidebar w-full mt-auto"
          type="button"
          @click="$emit('footerButtonSubmitted')"
          :disabled="footerButtonDisabled"
        >
          <component :is="IconAddCircle" class="shrink-0 w-5 h-5" />        
          {{ footerButtonLabel}}
        </Button>
      </slot>
    </div>   
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import Skeleton from 'primevue/skeleton';
import IconSquareRotated from './icon/icon-square-rotated.vue';
import IconAddCircle from './icon/icon-add-circle.vue';

type SidebarButtons = {
  buttons: { label: string; tab: string; icon: any, count?: number }[];
  status: { label: string; color: string; fill: string }[];
};

export default defineComponent({
  name: 'SideBar',
  setup() {
    return {
      Skeleton,
      IconSquareRotated,
      IconAddCircle,
    };
  },
  props: {
    sidebarButtons: {
      type: Object as () => SidebarButtons,
      required: true,
    },
    footerButtonLabel: {
      type: String,
      default: "Criar",
    },
    activeSidebarValue: {
      type: String,
      default: "all",
    },  
    loading: {
      type: Boolean,
      default: false,
    },
    statusTitle: {
      type: String,
      default: "Status",
    },
    footerButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["buttonClick", "statusClick", "footerButtonSubmitted"],
});
</script>

<style>
.icon-size-sidebar {
  width: 24px;
  height: 24px;
}

.text-sidebar {
  font-size: 14px !important;
}

@media (max-height: 710px) {
  .status-sidebar {
    display: none !important;
  }
}

.status-sidebar p {
  font-size: 14px !important;
}

h3 {
  margin: 0 !important;
}
</style>